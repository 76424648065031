// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-my-files-tsx": () => import("./../src/pages/my-files.tsx" /* webpackChunkName: "component---src-pages-my-files-tsx" */),
  "component---src-pages-now-tsx": () => import("./../src/pages/now.tsx" /* webpackChunkName: "component---src-pages-now-tsx" */),
  "component---src-templates-book-tsx": () => import("./../src/templates/book.tsx" /* webpackChunkName: "component---src-templates-book-tsx" */),
  "component---src-templates-film-tsx": () => import("./../src/templates/film.tsx" /* webpackChunkName: "component---src-templates-film-tsx" */),
  "component---src-templates-generic-tsx": () => import("./../src/templates/generic.tsx" /* webpackChunkName: "component---src-templates-generic-tsx" */)
}

